export const DatabaseList = [
    {
        imageUrl: "/image/skills/sql.png",
        altTag: "sql image",
        title: "SQL"
    },
    {
        imageUrl: "/image/skills/postgres.png",
        altTag: "Postgres image",
        title: "PostgreSQL"
    },
]